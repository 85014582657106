import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
//TODO: FIX THE FORM <> FOR USERNAME AND PASSWORD fuckin idiot

const Login = () =>{
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/LoginScript.js";
        script.async = true;
        document.body.appendChild(script);
    });

    const navigate = useNavigate();

    const handleClick = async () => {
        await new Promise(resolve => setTimeout(resolve, 500));
        if(localStorage.getItem("ticket") == 'NOT_LOGGED_IN'){
            //DO NOTHING
        } else{
            navigate('/user')
        }
    }

    return ( 
        <div id = 'loginPage'>
            <link rel="stylesheet" href="/css/loginPageStyles.css"/>
            <div id='loginPageContainer'>
                <div id='loginBoxContainer'>
                    <h2 id='loginTitle'>Login</h2>
                    <div id='userNpassContainer'>
                        <div className='userContainer'>
                            <p>Username:</p>
                            <p className='edtText' contentEditable='true' id="userNameEdt"></p>
                        </div>

                        <div id='passContainer'>
                            <p>Password:</p>
                            <div id='passHolder'>
                                <p className='edtText' contentEditable='true' id="passwordEdt"></p>
                                <FaEye id='showPass'/>
                            </div>
                        </div>
                        <u id='forgotPass'>Forgot Password?</u>

                        <input type='checkbox' id='remember'/>
                        <label id='remem_label' for='remember'>Remember Me?</label>
                    </div>

                    <button id='submit' onClick={handleClick}> 
                        Login
                    </button>
                    
                </div>

                <div id="login-infoContainer">
                    <img id='login-logo' src='https://cdn.vectorstock.com/i/500p/77/94/your-logo-here-placeholder-symbol-vector-25817794.jpg' alt='logo here.png'/>
                    <p id='login-blurb'>Hi there,
                        We are proven.care! Our mission is to provide visbility and accountability for loved ones that are living in nursing homes all over the country. Have any questions? Please reach out, we would love to be of any help :)
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;