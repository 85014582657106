import React, {useEffect, Fragment} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import ResidentPage from './pages/ResidentPage';
import ResidentMessages from './pages/ResidentMessages';

const App = () => {
    useEffect(() => {
        const script = document.createElement('link');
        script.rel = "stylesheet";
        script.href = "/css/AppStyles.css";
        document.body.appendChild(script);
    }) 

    return(
        <Router>
            <Fragment>
                <Routes>
                    <Route exact path='/' element={<LandingPage/>}/>
                    <Route exact path='/login' element={<Login/>} />
                    <Route exact path='/user' element={<ResidentPage/>} />
                    <Route path='/user/messages' element={<ResidentMessages/>}/>
                </Routes>
            </Fragment>
        </Router>
    );
}

export default App;