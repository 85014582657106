import React, {useEffect} from "react";

import { GrLinkPrevious } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const ResidentPage = () =>{
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/ResidentPageScript.js";
        script.async = true;
        document.body.appendChild(script);
    });
    return (
    <div id = 'ResidentPage'>
        <link rel="stylesheet" href="/css/ResidentPageStyles.css"/>
        <div id = 'ResidentPageContainer'>
            <div id='navbar'>
                <div className ="nav-icons">
                    <GrLinkPrevious className='icon' id="navbar-prev-icon"/>
                </div>
                <div className="name">
                    <h2>Proven.Care</h2>
                </div>

                <div className="nav-icons" id="navbar-hamb-icon">
                    <GiHamburgerMenu className='icon'/>
                </div>
        </div>

            <div id = 'resPage_info'>
                <div id = 'resPage_info_top'>
                    <div id = 'resPage_picture'>
                        <img src='https://static.tvtropes.org/pmwiki/pub/images/freeman_oblivion.png' alt= 'logo' id='respage_pic'/>
                    </div>
                    <div id = 'resPage_info-top_inf'>
                        <div id = 'info_top_half'>
                            <div id = 'resPage_name'>
                                <p className='value' id="value_name">Cool Guy Morgan Freeman</p>
                                <div id='name_line'></div>
                                <p>Name</p>
                            </div>
                        </div>
                        <div id = 'info_bottom_half'>
                            <div id = 'resPage_bed'>
                                <p className='value' id="value_bedName">194</p>
                                    <div id='name_line'></div>
                                    <p>Bed Name</p>
                            </div>
                            <div id = 'resPage_id'>
                                <p className='value' id="value_id">7</p>
                                    <div id='name_line'></div>
                                    <p>ID</p>
                            </div>
                        </div>
                    </div>
                    
                    <div id = 'resPage_buttons'>
                        <button id='resPageBtn'>Messages</button>
                        <button id='resPageBtn'>Schedule</button>
                    </div>
                </div>
                <div id = 'resPage_info_bottom'>
                    <div id = 'bottom_info'>
                        <p className='value' id="value_DOB">June 1, 1937</p>
                        <div id='name_line'></div>
                        <p>DOB</p>
                    </div>
                    
                    <div id = 'bottom_info'>
                        <p className='value' id="value_gender">Male</p>
                        <div id='name_line'></div>
                        <p>Gender</p>
                    </div>

                    <div id = 'bottom_info'>
                        <p className='value' id="value_admitDate">July 13, 2024</p>
                        <div id='name_line'></div>
                        <p>Admit Date</p>
                    </div>
                </div>
            </div>
            
            <div id = 'divider'></div>

            <div className = 'resPage_interactions'>
                <h3>Last Interactions:</h3>
                <div id='interactionsContainer'>
                    <h3 id="noActionsWarn">No Past Actions Found</h3>
                    <button id='interaction_more'>More</button>
                </div>
            
            </div>
        
            <div id = 'divider'></div>

            <div className='Notes'>
                <h3>Notes:</h3>
                <div id='notesContainer'>
                    <div id='notesBox'>
                        <p id="res_note">The notes are going to go in here...</p>
                    </div>
                </div>
            </div>

            <div id = 'divider'></div>

            <div className='contacts'>
                <h3>Contacts:</h3>
                <div id='contactsContainer'>
                    <h3 id="noContWarn">No Contacts Found</h3>
                    <button id='contacts_more'>More</button>
                </div>
            </div>

            
            <div id = 'divider'></div>
            
            <div id='genReportContainer'>
                <button className='generateReport'>Generate Report</button>
            </div>
        </div>
    </div>
    );
}

export default ResidentPage;